import React from 'react'
import {Helmet} from 'react-helmet'
import Link from "gatsby-link"
import MediaQuery from 'react-responsive'
import DarkModeToggle from './basicDarkModeToggle';
import useComponentVisible from '../hooks/useComponentVisible'

const curEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"
const Menu = () => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  return (
    <>
      <nav ref={ref}>
        {isComponentVisible && (
          <div className="menu-block" style={{
            position: 'absolute',
            right: 0,
            top: 0,
            padding: '0 0.8rem',
            borderRadius: '0.2rem',
            maxWidth: '90%',
            margin: '1rem 1rem',
            zIndex: 10,
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.12)'
          }}>
            <ul style={{
              listStyle: `none`,
              margin: 0
            }}>
              <li><Link to='/resume/' activeClassName="active" >
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="user-graduate" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-user-graduate fa-w-14 fa-5x"><path fill="currentColor" d="M13.2 100l6.8 2v37.6c-7 4.2-12 11.5-12 20.3 0 8.4 4.6 15.4 11.1 19.7L3.5 242c-1.7 6.9 2.1 14 7.6 14h41.8c5.5 0 9.3-7.1 7.6-14l-15.6-62.3C51.4 175.4 56 168.4 56 160c0-8.8-5-16.1-12-20.3v-30.5L90.6 123C84 139.4 80 157.2 80 176c0 79.5 64.5 144 144 144s144-64.5 144-144c0-18.8-4-36.6-10.6-53l77.4-23c17.6-5.2 17.6-34.8 0-40L240.9 2.5C235.3.8 229.7 0 224 0s-11.3.8-16.9 2.5L13.2 60c-17.6 5.2-17.6 34.8 0 40zM224 272c-52.9 0-96-43.1-96-96 0-14.1 3.3-27.3 8.8-39.3l70.4 20.9c14.8 4.4 27.2 2 33.8 0l70.4-20.9c5.5 12 8.8 25.3 8.8 39.3-.2 52.9-43.3 96-96.2 96zm-3.2-223.5c1-.3 3.3-.9 6.5 0L333.5 80l-106.3 31.5c-2.1.6-4.2.7-6.5 0L114.5 80l106.3-31.5zm98.6 272.1L224 400l-95.4-79.4C57.1 323.7 0 382.2 0 454.4v9.6c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-9.6c0-72.2-57.1-130.7-128.6-133.8zM200 464H48v-9.6c0-40.4 27.9-74.4 66-83.5l86 71.6V464zm200 0H248v-21.5l86-71.6c38.1 9.1 66 43.1 66 83.5v9.6z" className=""></path></svg>
               Resume</Link></li>
              { curEnv != "production" && (
                <li><Link to='/projects/' activeClassName="active" >
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="brackets-curly" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-brackets-curly fa-w-18 fa-5x"><path fill="currentColor" d="M208 32h-88a56 56 0 0 0-56 56v77.49a40 40 0 0 1-11.72 28.29L7 239a24 24 0 0 0 0 34l45.24 45.24A40 40 0 0 1 64 346.52V424a56 56 0 0 0 56 56h88a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16h-88a8 8 0 0 1-8-8v-77.48a88.06 88.06 0 0 0-25.78-62.24L57.93 256l28.29-28.28A88.06 88.06 0 0 0 112 165.48V88a8 8 0 0 1 8-8h88a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zm361 207l-45.25-45.24A40.07 40.07 0 0 1 512 165.48V88a56 56 0 0 0-56-56h-88a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h88a8 8 0 0 1 8 8v77.48a88 88 0 0 0 25.78 62.24L518.06 256l-28.28 28.28A88 88 0 0 0 464 346.52V424a8 8 0 0 1-8 8h-88a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h88a56 56 0 0 0 56-56v-77.49a40 40 0 0 1 11.72-28.29L569 273a24 24 0 0 0 0-34z" className=""></path></svg>
                Projects</Link></li>
              )}
              <li><Link to='/ride/' activeClassName="active" >
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="bicycle" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 640 512" className="svg-inline--fa fa-bicycle fa-w-20 fa-5x"><path fill="currentColor" d="M514.115 192.017c-17.637-.285-34.469 3.005-49.832 9.181l-79.29-127.746A20 20 0 0 0 368 64h-68c-6.627 0-12 5.373-12 12v16c0 6.627 5.373 12 12 12h56.874l32.276 52H256v-16c0-6.627-5.373-12-12-12h-96c-11.046 0-20 8.954-20 20s8.954 20 20 20h61.187l-25.65 36.644c-16.797-8.102-35.634-12.643-55.532-12.644C57.375 191.998-.443 250.196.003 320.824.446 391.137 57.583 448 128 448c58.192 0 107.306-38.835 122.859-92H284a20.005 20.005 0 0 0 16.385-8.53l110.038-157.197 19.539 31.48c-28.136 23.519-46.021 58.892-45.962 98.445.104 68.88 57.908 127.158 126.785 127.797 71.601.664 129.787-57.467 129.21-129.048-.556-69.152-56.736-125.812-125.88-126.93zM128 408c-48.523 0-88-39.477-88-88s39.477-88 88-88a87.552 87.552 0 0 1 32.134 6.075L99.615 324.53C90.342 337.781 99.857 356 116 356h92.294c-13.785 30.625-44.589 52-80.294 52zm26.413-92l38.641-55.201c13.409 14.722 21.898 33.997 22.852 55.201h-61.493zm119.174 0h-17.655c-1.069-34.805-16.026-66.113-39.524-88.563L238.413 196h119.174l-84 120zm234.284 91.905c-45.514-2.092-82.216-39.219-83.815-84.752-.924-26.302 9.764-50.177 27.328-66.888l47.843 77.08c3.495 5.631 10.894 7.362 16.524 3.867l13.594-8.438c5.631-3.495 7.362-10.893 3.867-16.524l-47.351-76.287c9.012-2.809 18.641-4.205 28.626-3.928 45.797 1.27 83.314 38.07 85.418 83.837 2.379 51.775-40.258 94.413-92.034 92.033z" className=""></path></svg>
              Ride</Link></li>
              { curEnv === "development" && (
                <li><Link to='/notes/' activeClassName="active" >
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="feather" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-feather fa-w-16 fa-3x"><path fill="currentColor" d="M467.1 44.9C438.24 16.04 401.59 0 361.59 0c-46.7 0-97.98 21.85-146.78 70.66l-85.75 85.76C54 231.47 56.69 352.92 72.69 405.37L7.03 471.03c-9.37 9.37-9.37 24.57 0 33.94 9.37 9.37 24.57 9.37 33.94 0l65.6-65.6c17.44 5.3 42.43 9.15 70.88 9.15 57.19 0 128.04-15.48 178.13-65.57l85.76-85.75c90.61-90.62 88.32-189.75 25.76-252.3zM147.37 398.57L193.94 352h124.12c-44.62 41.83-106.87 48.46-140.61 48.46-11.41.01-21.29-.81-30.08-1.89zM350.58 320H225.94l64-64h123.81c-2.23 2.4-4.01 4.83-6.39 7.21L350.58 320zm88.31-96H321.94l22.51-22.51c9.37-9.37 9.37-24.57 0-33.94-9.37-9.37-24.57-9.37-33.94 0l-197 197c-5.27-45.97-.29-124.34 49.52-174.15 0 0 18.71-18.71 85.75-85.76 37.02-37.02 76.03-56.58 112.8-56.58 26.63 0 51.37 10.66 71.53 30.82 39.17 39.16 40.02 92.25 5.78 145.12z" className=""></path></svg>
                Journal</Link></li>
              )}
              <li><Link to='/months/' activeClassName="active" >
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="camera-alt" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-camera-alt fa-w-16 fa-5x"><path fill="currentColor" d="M256 408c-66.2 0-120-53.8-120-120s53.8-120 120-120 120 53.8 120 120-53.8 120-120 120zm0-192c-39.7 0-72 32.3-72 72s32.3 72 72 72 72-32.3 72-72-32.3-72-72-72zm-24 72c0-13.2 10.8-24 24-24 8.8 0 16-7.2 16-16s-7.2-16-16-16c-30.9 0-56 25.1-56 56 0 8.8 7.2 16 16 16s16-7.2 16-16zm110.7-145H464v288H48V143h121.3l24-64h125.5l23.9 64zM324.3 31h-131c-20 0-37.9 12.4-44.9 31.1L136 95H48c-26.5 0-48 21.5-48 48v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V143c0-26.5-21.5-48-48-48h-88l-14.3-38c-5.8-15.7-20.7-26-37.4-26z" className=""></path></svg>
              Monthly</Link></li>
              <li>
                <DarkModeToggle mobile />
              </li>
              {/*
              <li><Link to='/about/' activeClassName="active" > <i className="fas fa-info-circle"></i> About</Link></li>
              <li><Link to='/months/' > <i className="fas fa-camera"></i> 12 months</Link></li>
              <li><Link to='/resume/' > <i className="fas fa-user-graduate"></i> Resume</Link></li>
              */}
            </ul>
          </div>
        )}
        <a className="link" onClick={() => setIsComponentVisible(true)} >
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="bars" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-bars fa-w-14 fa-5x"><path fill="currentColor" d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z" className=""></path></svg>
        </a>
        {/*
        {!isComponentVisible && (
        )}
        */}
      </nav>
      </>
    );
  }

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initOpacity: this.props.opacity ? this.props.opacity : 0,
      opacity: this.props.opacity ? this.props.opacity : 0
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = ev => {
    const opacity = Math.min(window.scrollY / 100, 1);
    this.setState({
      opacity: Math.max(opacity, this.state.initOpacity)
    });
  };

  render() {
        const { x, y, opacity } = this.state
        const siteTitle = this.props.site.siteMetadata.title
        const siteAuthor = this.props.site.siteMetadata.author
        const siteDescription = this.props.site.siteMetadata.description
        const siteAuthorTwitter = "https://twitter.com/" + this.props.site.siteMetadata.social.twitter;
        const siteAuthorInstagram = "https://instagram.com/" + this.props.site.siteMetadata.social.instagram;
        const siteAuthorGitlab = "https://gitlab.com/" + this.props.site.siteMetadata.social.gitlab;
        const siteAuthorStrava = "https://www.strava.com/athletes/" + this.props.site.siteMetadata.social.strava;
        return (
          <>
            <Helmet>
              <title>{siteTitle}</title>
              <meta name="description" content={`${siteDescription}`}/>
              <meta name="author" content={`${siteAuthor}`}/>
              <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1.0" />
              <link rel="icon" type="image/png" href="/favicon.png"/>
              <link rel="apple-touch-icon" href="/favicon.png"/>
            </Helmet>
            <header>
              <div
                style={{
                  margin: 'auto',
                  width: '100%',
                  maxWidth: '60rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                  <Link to={`/`}
                    style={{
                        flex: 1,
                        width: '100%',
                        paddingLeft: '1rem'
                    }}>
                      <h3 style={{
                        marginBottom: '0',
                        display: 'inline-block',
                      }}
                      className='head'
                      >
                          {siteTitle}
                      </h3>
                  </Link>
                  <a className="link" href={siteAuthorTwitter} target="_blank" rel="noopener noreferrer">
                      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-twitter fa-w-16 fa-5x"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" className=""></path></svg>
                  </a>
                  <a className="link" href={siteAuthorInstagram} target="_blank" rel="noopener noreferrer">
                      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-instagram fa-w-14 fa-5x"><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" className=""></path></svg>
                  </a>
                  {/*
                    <a className="link" href={siteAuthorStrava} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-strava" aria-hidden="true"/>
                    </a>
                    */}
                  <Link className="link" to='/collections/' activeClassName="active" >
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="globe-europe" className="svg-inline--fa fa-globe-europe fa-w-16" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm200 248c0 22.5-3.9 44.2-10.8 64.4h-20.3c-4.3 0-8.4-1.7-11.4-4.8l-32-32.6c-4.5-4.6-4.5-12.1.1-16.7l12.5-12.5v-8.7c0-3-1.2-5.9-3.3-8l-9.4-9.4c-2.1-2.1-5-3.3-8-3.3h-16c-6.2 0-11.3-5.1-11.3-11.3 0-3 1.2-5.9 3.3-8l9.4-9.4c2.1-2.1 5-3.3 8-3.3h32c6.2 0 11.3-5.1 11.3-11.3v-9.4c0-6.2-5.1-11.3-11.3-11.3h-36.7c-8.8 0-16 7.2-16 16v4.5c0 6.9-4.4 13-10.9 15.2l-31.6 10.5c-3.3 1.1-5.5 4.1-5.5 7.6v2.2c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8s-3.6-8-8-8H247c-3 0-5.8 1.7-7.2 4.4l-9.4 18.7c-2.7 5.4-8.2 8.8-14.3 8.8H194c-8.8 0-16-7.2-16-16V199c0-4.2 1.7-8.3 4.7-11.3l20.1-20.1c4.6-4.6 7.2-10.9 7.2-17.5 0-3.4 2.2-6.5 5.5-7.6l40-13.3c1.7-.6 3.2-1.5 4.4-2.7l26.8-26.8c2.1-2.1 3.3-5 3.3-8 0-6.2-5.1-11.3-11.3-11.3H258l-16 16v8c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-20c0-2.5 1.2-4.9 3.2-6.4l28.9-21.7c1.9-.1 3.8-.3 5.7-.3C358.3 56 448 145.7 448 256zM130.1 149.1c0-3 1.2-5.9 3.3-8l25.4-25.4c2.1-2.1 5-3.3 8-3.3 6.2 0 11.3 5.1 11.3 11.3v16c0 3-1.2 5.9-3.3 8l-9.4 9.4c-2.1 2.1-5 3.3-8 3.3h-16c-6.2 0-11.3-5.1-11.3-11.3zm128 306.4v-7.1c0-8.8-7.2-16-16-16h-20.2c-10.8 0-26.7-5.3-35.4-11.8l-22.2-16.7c-11.5-8.6-18.2-22.1-18.2-36.4v-23.9c0-16 8.4-30.8 22.1-39l42.9-25.7c7.1-4.2 15.2-6.5 23.4-6.5h31.2c10.9 0 21.4 3.9 29.6 10.9l43.2 37.1h18.3c8.5 0 16.6 3.4 22.6 9.4l17.3 17.3c3.4 3.4 8.1 5.3 12.9 5.3H423c-32.4 58.9-93.8 99.5-164.9 103.1z"></path>
                    </svg>
                  </Link>
                  {/*
                  <Link className="link" to='/collections/' activeClassName="active" >
                    <i className="fas fa-globe" aria-hidden="true"/>
                  </Link>
                  */}
                  <MediaQuery query="(min-device-width: 1224px)">
                    { curEnv === "development" && (
                      <Link className="link" to='/notes/' activeClassName="active" >
                        {/*
                        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="feather" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-feather fa-w-16 fa-3x"><path fill="currentColor" d="M467.11 44.89C438.25 16.03 401.61 0 361.61 0c-46.7 0-97.96 21.85-146.76 70.65l-85.74 85.74c-79.32 79.32-71.83 210.7-53.54 257.4L4.69 484.66A15.962 15.962 0 0 0 0 495.98C0 504.52 6.87 512 16.02 512c4.1 0 8.2-1.56 11.32-4.69l70.93-70.93c17.2 6.76 45.79 12.08 79.23 12.08 57.18 0 128.03-15.48 178.11-65.56l85.74-85.74c90.6-90.61 88.31-189.72 25.76-252.27zM151.76 179.04L237.5 93.3c40.08-40.08 83-61.27 124.11-61.27 30.97 0 59.62 12.28 82.85 35.51 42.32 42.32 45.75 100.1 13.13 156.69H310.42l37.24-37.24c6.26-6.26 6.26-16.39 0-22.65s-16.39-6.26-22.65 0L101.19 388.16c-9.9-43.33-13.3-145.26 50.57-209.12zm25.74 237.38c-23.3 0-40.97-2.79-53.49-5.79l58.75-58.75 158.19.37-7.99 7.99c-48.88 48.88-118.38 56.18-155.46 56.18zm195.38-96.09H214.32l64.07-64.07h156.34c-5.11 6.12-61.85 64.07-61.85 64.07z" className=""></path>
                        </svg>
                          */}
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="feather" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-feather fa-w-16 fa-3x"><path fill="currentColor" d="M467.1 44.9C438.24 16.04 401.59 0 361.59 0c-46.7 0-97.98 21.85-146.78 70.66l-85.75 85.76C54 231.47 56.69 352.92 72.69 405.37L7.03 471.03c-9.37 9.37-9.37 24.57 0 33.94 9.37 9.37 24.57 9.37 33.94 0l65.6-65.6c17.44 5.3 42.43 9.15 70.88 9.15 57.19 0 128.04-15.48 178.13-65.57l85.76-85.75c90.61-90.62 88.32-189.75 25.76-252.3zM147.37 398.57L193.94 352h124.12c-44.62 41.83-106.87 48.46-140.61 48.46-11.41.01-21.29-.81-30.08-1.89zM350.58 320H225.94l64-64h123.81c-2.23 2.4-4.01 4.83-6.39 7.21L350.58 320zm88.31-96H321.94l22.51-22.51c9.37-9.37 9.37-24.57 0-33.94-9.37-9.37-24.57-9.37-33.94 0l-197 197c-5.27-45.97-.29-124.34 49.52-174.15 0 0 18.71-18.71 85.75-85.76 37.02-37.02 76.03-56.58 112.8-56.58 26.63 0 51.37 10.66 71.53 30.82 39.17 39.16 40.02 92.25 5.78 145.12z" className=""></path>
                        </svg>
                      </Link>
                    )}
                    <Link className="link" to='/months/' activeClassName="active" >
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="camera-alt" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-camera-alt fa-w-16 fa-5x"><path fill="currentColor" d="M256 408c-66.2 0-120-53.8-120-120s53.8-120 120-120 120 53.8 120 120-53.8 120-120 120zm0-192c-39.7 0-72 32.3-72 72s32.3 72 72 72 72-32.3 72-72-32.3-72-72-72zm-24 72c0-13.2 10.8-24 24-24 8.8 0 16-7.2 16-16s-7.2-16-16-16c-30.9 0-56 25.1-56 56 0 8.8 7.2 16 16 16s16-7.2 16-16zm110.7-145H464v288H48V143h121.3l24-64h125.5l23.9 64zM324.3 31h-131c-20 0-37.9 12.4-44.9 31.1L136 95H48c-26.5 0-48 21.5-48 48v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V143c0-26.5-21.5-48-48-48h-88l-14.3-38c-5.8-15.7-20.7-26-37.4-26z" className=""></path></svg>
                      {/*
                      <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="camera-alt" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-camera-alt fa-w-16 fa-5x"><path fill="currentColor" d="M256 408c-66.2 0-120-53.8-120-120s53.8-120 120-120 120 53.8 120 120-53.8 120-120 120zm0-208c-48.5 0-88 39.5-88 88s39.5 88 88 88 88-39.5 88-88-39.5-88-88-88zm-32 88c0-17.6 14.4-32 32-32 8.8 0 16-7.2 16-16s-7.2-16-16-16c-35.3 0-64 28.7-64 64 0 8.8 7.2 16 16 16s16-7.2 16-16zM324.3 64c3.3 0 6.3 2.1 7.5 5.2l22.1 58.8H464c8.8 0 16 7.2 16 16v288c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h110.2l20.1-53.6c2.3-6.2 8.3-10.4 15-10.4h131m0-32h-131c-20 0-37.9 12.4-44.9 31.1L136 96H48c-26.5 0-48 21.5-48 48v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V144c0-26.5-21.5-48-48-48h-88l-14.3-38c-5.8-15.7-20.7-26-37.4-26z" className=""></path></svg>
                      */}
                    </Link>
                    <Link className="link" to='/ride/' activeClassName="active" >
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="bicycle" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 640 512" className="svg-inline--fa fa-bicycle fa-w-20 fa-5x"><path fill="currentColor" d="M514.115 192.017c-17.637-.285-34.469 3.005-49.832 9.181l-79.29-127.746A20 20 0 0 0 368 64h-68c-6.627 0-12 5.373-12 12v16c0 6.627 5.373 12 12 12h56.874l32.276 52H256v-16c0-6.627-5.373-12-12-12h-96c-11.046 0-20 8.954-20 20s8.954 20 20 20h61.187l-25.65 36.644c-16.797-8.102-35.634-12.643-55.532-12.644C57.375 191.998-.443 250.196.003 320.824.446 391.137 57.583 448 128 448c58.192 0 107.306-38.835 122.859-92H284a20.005 20.005 0 0 0 16.385-8.53l110.038-157.197 19.539 31.48c-28.136 23.519-46.021 58.892-45.962 98.445.104 68.88 57.908 127.158 126.785 127.797 71.601.664 129.787-57.467 129.21-129.048-.556-69.152-56.736-125.812-125.88-126.93zM128 408c-48.523 0-88-39.477-88-88s39.477-88 88-88a87.552 87.552 0 0 1 32.134 6.075L99.615 324.53C90.342 337.781 99.857 356 116 356h92.294c-13.785 30.625-44.589 52-80.294 52zm26.413-92l38.641-55.201c13.409 14.722 21.898 33.997 22.852 55.201h-61.493zm119.174 0h-17.655c-1.069-34.805-16.026-66.113-39.524-88.563L238.413 196h119.174l-84 120zm234.284 91.905c-45.514-2.092-82.216-39.219-83.815-84.752-.924-26.302 9.764-50.177 27.328-66.888l47.843 77.08c3.495 5.631 10.894 7.362 16.524 3.867l13.594-8.438c5.631-3.495 7.362-10.893 3.867-16.524l-47.351-76.287c9.012-2.809 18.641-4.205 28.626-3.928 45.797 1.27 83.314 38.07 85.418 83.837 2.379 51.775-40.258 94.413-92.034 92.033z" className=""></path></svg>
                     </Link>
                    { curEnv != "production" && (
                      <Link className="link" to='/projects/' activeClassName="active" >
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="brackets-curly" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-brackets-curly fa-w-18 fa-5x"><path fill="currentColor" d="M208 32h-88a56 56 0 0 0-56 56v77.49a40 40 0 0 1-11.72 28.29L7 239a24 24 0 0 0 0 34l45.24 45.24A40 40 0 0 1 64 346.52V424a56 56 0 0 0 56 56h88a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16h-88a8 8 0 0 1-8-8v-77.48a88.06 88.06 0 0 0-25.78-62.24L57.93 256l28.29-28.28A88.06 88.06 0 0 0 112 165.48V88a8 8 0 0 1 8-8h88a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zm361 207l-45.25-45.24A40.07 40.07 0 0 1 512 165.48V88a56 56 0 0 0-56-56h-88a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h88a8 8 0 0 1 8 8v77.48a88 88 0 0 0 25.78 62.24L518.06 256l-28.28 28.28A88 88 0 0 0 464 346.52V424a8 8 0 0 1-8 8h-88a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h88a56 56 0 0 0 56-56v-77.49a40 40 0 0 1 11.72-28.29L569 273a24 24 0 0 0 0-34z" className=""></path></svg>
                       </Link>
                    )}
                    <Link className="link" to='/resume/' activeClassName="active" >
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="user-graduate" role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-user-graduate fa-w-14 fa-5x"><path fill="currentColor" d="M13.2 100l6.8 2v37.6c-7 4.2-12 11.5-12 20.3 0 8.4 4.6 15.4 11.1 19.7L3.5 242c-1.7 6.9 2.1 14 7.6 14h41.8c5.5 0 9.3-7.1 7.6-14l-15.6-62.3C51.4 175.4 56 168.4 56 160c0-8.8-5-16.1-12-20.3v-30.5L90.6 123C84 139.4 80 157.2 80 176c0 79.5 64.5 144 144 144s144-64.5 144-144c0-18.8-4-36.6-10.6-53l77.4-23c17.6-5.2 17.6-34.8 0-40L240.9 2.5C235.3.8 229.7 0 224 0s-11.3.8-16.9 2.5L13.2 60c-17.6 5.2-17.6 34.8 0 40zM224 272c-52.9 0-96-43.1-96-96 0-14.1 3.3-27.3 8.8-39.3l70.4 20.9c14.8 4.4 27.2 2 33.8 0l70.4-20.9c5.5 12 8.8 25.3 8.8 39.3-.2 52.9-43.3 96-96.2 96zm-3.2-223.5c1-.3 3.3-.9 6.5 0L333.5 80l-106.3 31.5c-2.1.6-4.2.7-6.5 0L114.5 80l106.3-31.5zm98.6 272.1L224 400l-95.4-79.4C57.1 323.7 0 382.2 0 454.4v9.6c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-9.6c0-72.2-57.1-130.7-128.6-133.8zM200 464H48v-9.6c0-40.4 27.9-74.4 66-83.5l86 71.6V464zm200 0H248v-21.5l86-71.6c38.1 9.1 66 43.1 66 83.5v9.6z" className=""></path></svg>
                    </Link>
                    {/*
                    <Link className="link" to='/about/' activeClassName="active" > <i className="fas fa-info-circle"></i> </Link>
                    */}
                    <hr className="verticalDivider"  />
                    <DarkModeToggle />
                  </MediaQuery>
                  <MediaQuery query="(max-device-width: 1224px)">
                    <Menu/>
                  </MediaQuery>
                </div>
              </header>
            </>
        )
    }
}

export default Header
